import { Route, Routes, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import 'boxicons/css/boxicons.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  HomePage,
  FlexDataPage,
  ErrorPage,
  PowerBIReportPage,
  ImpersonatePage,
  PayerGroupPage,
  PayerTypePage,
  WorklistPage,
  IpaPage,
  UserMaintenancePage,
  RoleMaintenancePage,
  ChargeCodeReferencePage,
  ProviderPage,
  MenuItemMaintenancePage,
  DatabricksMappingPage,
  PatientMappingPage,
  OaInsightPage,
} from '.';
import {
  setCurrentMenuItem,
  setLoadingStatus,
  useAppDispatch,
  useAppSelector,
  useGetMenuItemsQuery,
} from 'api';
import { useEffect } from 'react';
import { LicenseInfo } from '@mui/x-license';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { ProtectedRoute } from './ProtectedRoute';

LicenseInfo.setLicenseKey(
  '3a9f58c5b39254f09d01d4c85a29d811Tz04OTkwNCxFPTE3NDY3MTE2NTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

export const App = () => {
  // Hooks
  const { authState } = useOktaAuth();
  // const { isLoading, isFetching } = useGetUserQuery();
  const { mockUser } = useAppSelector(state => state.appUser);
  const { data: menuItems } = useGetMenuItemsQuery(mockUser?.id, {
    skip: !mockUser,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const isLoggedIn = isLoading || isFetching || user;
  const { currentMenuItem } = useAppSelector(state => state.menuItem);

  // This gets the menu item id from the url when a user goes directly to a powerbi or flexdata page without clicking a link
  // and sets it as the curentMenuItem. The purpose is to preserve the styling of the menu items whether a user clicks a link
  // or goes directly via url.
  useEffect(() => {
    if (menuItems && menuItems?.originalMenuItems.length > 0) {
      let mid: any;
      const url = window.location.href;
      if (url.includes('powerbi')) {
        const match = url.match(/powerbi\/([0-9]+)/);
        if (match && match.length > 0) mid = match[1].toString();
      } else if (url.includes('flexdata')) {
        const match = url.match(/flexdata\/([0-9]+)/);
        if (match && match.length > 1) mid = match[1].toString();
      } else if (url.includes('datamaintenance')) {
        const match = url.match(/datamaintenance\/\w+\/([0-9]+)/);
        if (match && match.length > 1) mid = match[1].toString();
      } else if (url.includes('worklist')) {
        const match = url.match(/worklist\/([0-9]+)\/([0-9]+)/);
        if (match && match.length > 1) mid = match[1].toString();
      }

      if (mid && currentMenuItem?.id === -1) {
        const menuItem = menuItems.originalMenuItems.find(
          item => item.id === parseInt(mid)
        );

        if (menuItem) dispatch(setCurrentMenuItem(menuItem));
        else {
          dispatch(setLoadingStatus(false));
          navigate(`/error`);
        }
      }
    }
  });

  useEffect(() => {
    if (authState && authState.isAuthenticated && authState.accessToken) {
      localStorage.setItem('authToken', authState.accessToken.accessToken);

      const lastUrl = getCookie('lastUrl');
      if (lastUrl) {
        document.cookie =
          'lastUrl=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC';
        window.location.href = lastUrl;
      }
    }
  }, [authState]);

  function getCookie(name: string) {
    const cookieArr = document.cookie.split(';');
    for (let cookie of cookieArr) {
      cookie = cookie.trim();
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
    return null;
  }

  return (
    <Box height="100%" overflow="hidden" id="app" minWidth="1050px">
      <Routes>
        <Route path="/login/callback" element={<LoginCallback />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/powerbi/:mid" element={<PowerBIReportPage />} />
          <Route path="/flexdata/:mid" element={<FlexDataPage />} />
          <Route path="/impersonate" element={<ImpersonatePage />} />
          <Route
            path="/datamaintenance/payertype/:mid"
            element={<PayerTypePage />}
          />
          <Route
            path="/datamaintenance/payergroup/:mid"
            element={<PayerGroupPage />}
          />
          <Route path="/datamaintenance/ipa/:mid" element={<IpaPage />} />
          <Route
            path="/datamaintenance/chargecodereference/:mid"
            element={<ChargeCodeReferencePage />}
          />
          <Route
            path="/datamaintenance/provider/:mid"
            element={<ProviderPage />}
          />
          <Route
            path="/datamaintenance/mapping/:mid"
            element={<DatabricksMappingPage />}
          />
          <Route
            path="/datamaintenance/patientMapping/:mid"
            element={<PatientMappingPage />}
          />
          <Route
            path="/datamaintenance/subscribers/:mid"
            element={<OaInsightPage />}
          />
          <Route path="/worklist/:mid/:wlid" element={<WorklistPage />} />
          <Route path="/usermaintenance" element={<UserMaintenancePage />} />
          <Route path="/rolemaintenance" element={<RoleMaintenancePage />} />
          <Route
            path="/menuItemMaintenance"
            element={<MenuItemMaintenancePage />}
          />
          <Route
            path="/error"
            element={
              <ErrorPage
                title="404 Not Found"
                message="I am unable to find the page you requested."
              />
            }
          />
        </Route>
      </Routes>
    </Box>
  );
};

export default App;
