import { Role } from '../role';
import { ColumnConfiguration, GridData, ValueOption } from '../table';

export type MenuItem = {
  id: number;
  parentId: number;
  powerBIReportGuid?: string;
  powerBIReportName?: string;
  parentMenuItemText: string;
  description: string;
  displayOrder: number;
  text: string;
  iconClass?: string;
  type: number;
  url: string;
  active?: boolean;
  roles: Role[];
  helpUrl?: string;
  lastViewed?: Date;
  tags?: string[] | null;
};

export type MenuItemDisplay = MenuItem & {
  children: MenuItemDisplay[];
};

export type MenuItems = {
  menuItemsDisplay: MenuItemDisplay[];
  originalMenuItems: MenuItem[];
  favorites: MenuItem[];
};

export const MenuItemTypes = {
  none: 0,
  powerBI: 1,
  flexData: 2,
  legacy: 3,
  generic: 4,
  worklist: 5,
};

export const getMenuItemType = (type: number | undefined) => {
  switch (type) {
    case MenuItemTypes.powerBI:
      return 'Powerbi';
    case MenuItemTypes.flexData:
      return 'Flexdata';
    case MenuItemTypes.legacy:
      return 'Legacy';
    case MenuItemTypes.generic:
      return 'Generic';
    case MenuItemTypes.worklist:
      return 'Worklist';
  }

  return '';
};

//TODO: Return columns from API endpoint
const menuItemColumns: ColumnConfiguration[] = [
  {
    name: 'text',
    displayName: 'Menu Item',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'parentMenuItemText',
    displayName: 'Parent Menu Item',
    type: 'string',
    required: true,
    defaultValue: '',
    editable: false,
    flex: 1,
  },
  {
    name: 'displayOrder',
    displayName: 'Display Order',
    type: 'number',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'active',
    displayName: 'Enabled',
    type: 'boolean',
    required: true,
    defaultValue: '',
    editable: true,
    flex: 1,
  },
  {
    name: 'actions',
    type: 'actions',
    displayName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    required: true,
    editable: false,
    defaultValue: '',
  },
];

export type AddEditMenuItemValue = {
  menuItemId?: number;
  menuItemText: string | null;
  menuItemDisplayOrder: number | null;
  parentMenuItemText: string | null;
  enabled: boolean;
  selectedPowerBiReport: ValueOption | null;
  selectedParentMenuItem: ValueOption | null;
  selectedHelpPage: ValueOption | null;
  selectedRoles: Role[];
  menuItemTags?: string[] | null;
};

export const defaultAddEditMenuItemValue: AddEditMenuItemValue = {
  menuItemText: null,
  menuItemDisplayOrder: null,
  parentMenuItemText: null,
  enabled: false,
  selectedPowerBiReport: null,
  selectedParentMenuItem: null,
  selectedHelpPage: null,
  selectedRoles: [],
  menuItemTags: null,
};

export type MenuItemRequest = {
  menuItem: MenuItem;
  roles: Role[];
};

export type ErrorState = {
  isError: boolean;
  message: string;
};

export type AddEditError = {
  textError: ErrorState;
  powerBiError: ErrorState;
  helpPageError: ErrorState;
  parentMenuItemError: ErrorState;
  menuItemTags: ErrorState;
  hasErrors: boolean;
};

export const convertMenuItemsToDisplay = (menuItems: MenuItem[]): MenuItems => {
  // This method creates a MenuItemDisplay and recursively populates the children.
  // The passed in menuItems excludes the parent to avoid any recursive infinite loops
  const populateChildren = (
    parentItem: MenuItem,
    menuItems: MenuItem[]
  ): MenuItemDisplay => {
    return {
      ...parentItem,
      children: menuItems
        .filter(mi => mi.parentId === parentItem.id)
        .sort((a, b) => {
          if (a.url === '#' && b.url === '#') {
            return (a.displayOrder ?? 0) - (b.displayOrder ?? 0);
          } else if (a.url === '#') {
            return -1;
          } else if (b.url === '#') {
            return 1;
          }
          if (a.text === null && b.text === null) {
            return 0;
          } else if (a.text === null) {
            return 1;
          } else if (b.text === null) {
            return -1;
          } else {
            return (a.text as string).localeCompare(b.text as string);
          }
        })
        .map(mi =>
          populateChildren(
            mi,
            menuItems.filter(ci => ci.id !== mi.id)
          )
        ),
    };
  };

  const x = menuItems
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .filter(mi => mi.parentId === -1)
    .map(pi =>
      populateChildren(
        pi,
        menuItems.filter(mi => mi.id !== pi.id)
      )
    );

  x.forEach(menuItem => {
    if (
      menuItem.children &&
      menuItem.children.length > 0 &&
      menuItem.url !== '#'
    ) {
      menuItem.children.sort((a, b) => {
        if (a.text === null && b.text === null) {
          return 0;
        } else if (a.text === null) {
          return 1;
        } else if (b.text === null) {
          return -1;
        } else {
          return a.text.localeCompare(b.text);
        }
      });
    }
  });

  const favoriteParentMenuItem = menuItems.filter(
    mi => mi.text === 'Favorites'
  );

  return {
    menuItemsDisplay: x,
    originalMenuItems: menuItems,
    favorites:
      favoriteParentMenuItem && favoriteParentMenuItem.length > 0
        ? menuItems.filter(mi => mi.parentId === favoriteParentMenuItem[0].id)
        : [],
  };
};

export const convertMenuItemsToGridData = (menuItems: MenuItem[]): GridData => {
  return {
    columns: menuItemColumns,
    rows: menuItems,
  };
};

export const convertFormDataToMenuItem = (
  addEditItemValue: AddEditMenuItemValue
): MenuItem | null => {
  const {
    menuItemId,
    menuItemText,
    menuItemDisplayOrder,
    parentMenuItemText,
    enabled,
    selectedPowerBiReport,
    selectedParentMenuItem,
    selectedHelpPage,
    selectedRoles,
    menuItemTags,
  } = addEditItemValue;

  const menuItem: MenuItem = {
    id: menuItemId ? menuItemId : -1, //Defauling menu item id to -1 for new menu item
    parentId: selectedParentMenuItem!.value as number,
    text: menuItemText ? menuItemText : '',
    parentMenuItemText: parentMenuItemText ? parentMenuItemText : '',
    description: menuItemText ? menuItemText : '',
    displayOrder: menuItemDisplayOrder ? menuItemDisplayOrder : 0,
    powerBIReportGuid: selectedPowerBiReport?.value
      ? selectedPowerBiReport.value.toString()
      : undefined,
    powerBIReportName: selectedPowerBiReport?.label
      ? selectedPowerBiReport.label
      : undefined,
    type: 1,
    url: '',
    active: enabled,
    roles: selectedRoles,
    helpUrl: selectedHelpPage?.value.toString()
      ? selectedHelpPage?.value.toString()
      : '',
    tags: menuItemTags ? menuItemTags : null,
  };

  return menuItem;
};

export const validateAddEditMenuItem = (
  addEditItemValue: AddEditMenuItemValue
): AddEditError | null => {
  let addEditError: AddEditError = {
    hasErrors: false,
    textError: { isError: false, message: '' },
    powerBiError: { isError: false, message: '' },
    helpPageError: { isError: false, message: '' },
    parentMenuItemError: { isError: false, message: '' },
    menuItemTags: { isError: false, message: '' },
  };

  const { menuItemText, selectedPowerBiReport, selectedParentMenuItem } =
    addEditItemValue;

  if (!menuItemText) {
    addEditError.hasErrors = true;
    addEditError.textError.isError = true;
    addEditError.textError.message = 'Menu item text needs to be defined.';
  } else if (menuItemText && menuItemText.length < 3) {
    addEditError.hasErrors = true;
    addEditError.textError.isError = true;
    addEditError.textError.message =
      'Menu item text must be at least 3 characters.';
  }

  if (!selectedPowerBiReport) {
    addEditError.hasErrors = true;
    addEditError.powerBiError.isError = true;
    addEditError.textError.message = 'Need to select powerbi report.';
  }

  if (!selectedParentMenuItem) {
    addEditError.hasErrors = true;
    addEditError.parentMenuItemError.isError = true;
    addEditError.parentMenuItemError.message =
      'Need to select parent menu item.';
  }

  return addEditError;
};

export const isAddEditMenuItemNull = (
  addEditItemValue: AddEditMenuItemValue
): boolean => {
  if (
    !addEditItemValue.menuItemDisplayOrder ||
    !addEditItemValue.menuItemText ||
    !addEditItemValue.selectedParentMenuItem ||
    !addEditItemValue.selectedPowerBiReport ||
    !addEditItemValue.selectedRoles
  )
    return true;

  return false;
};
