import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const ServerApiUrl = process.env.REACT_APP_SERVERAPI_URL || '/api/';
export const ReactAppUrl = process.env.REACT_APP_APP_URL || '/';

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: ServerApiUrl }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      extraOptions?: {} | undefined;
    },
    unknown,
    {} | unknown
  > =>
  async ({ url, method, data, extraOptions }) => {
    try {
      const token = localStorage.getItem('authToken');

      const config: AxiosRequestConfig = {
        url: baseUrl + url,
        method,
        data,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const result = await axios(config);
      return { data: result.data, meta: extraOptions };
    } catch (error: any) {
      const axiosError = error as AxiosError;
      if (
        axiosError.code === 'ERR_NETWORK' &&
        axiosError.message === 'Network Error' &&
        axiosError.response?.status === 0
      ) {
        // This error can occur for a few reasons:
        // Not specifying the protocol (http:// or https://), or incorrect URL, port or path
        // or due to a CORS error
        // We assume we are always using correct protocol, URL, port, path
        // so all errors like this will be assumed to be a CORS error.
        // As such this error is due to a failure of the app to access
        // the redirect to the okta login page due to user not being logged in.
        // In this case we will redirect to the page with the react app
        // which will cause the browser to handle the redirect correctly
        console.log('VIKRAM: CORS ERROR?');
        window.location.reload();
      }
      console.log(error);
      throw new AxiosError(error.response.data);
    }
  };
