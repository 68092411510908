import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material';
import {
  AlertSeverity,
  MenuItem,
  MenuItemRequest,
  AddEditMenuItemValue,
  Role,
  ValueOption,
  convertFormDataToMenuItem,
  defaultAddEditMenuItemValue,
  setAlert,
  setOpenTableModal,
  useAppDispatch,
  useAppSelector,
  useGetAllChildMenuItemsByParentIdQuery,
  useGetHelpPagesQuery,
  useGetPowerBiReportsQuery,
  useGetRolesQuery,
  useUpsertMenuItemMutation,
  AddEditError,
  validateAddEditMenuItem,
  updateTableRows,
  isAddEditMenuItemNull,
  useGetUnassignedPowerBiReportsQuery,
} from 'api';
import { FormWithLabel, ModalHeaderStyled } from 'app';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GridRowModel } from '@mui/x-data-grid-pro';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AddEditMenuItemModal = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { data: powerBiReportsData } = useGetPowerBiReportsQuery();
  const { data: unassignedPowerBiReportsData } =
    useGetUnassignedPowerBiReportsQuery();
  const { data: parentMenuItemsData } =
    useGetAllChildMenuItemsByParentIdQuery(1);
  const { data: helpPagesData } = useGetHelpPagesQuery();
  const { data: rolesData } = useGetRolesQuery();
  const [upsertAction] = useUpsertMenuItemMutation();
  const { selectedRow, rows } = useAppSelector(state => state.dataGridTable);

  // States
  const [powerBiReportValueOptions, setPowerBiReportValueOptions] =
    useState<ValueOption[]>();
  const [parentMenuItemValueOptions, setParentMenuItemValueOptions] =
    useState<ValueOption[]>();
  const [helpPageValueOptions, setHelpPageValueOptions] =
    useState<ValueOption[]>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [addEditMenuItemValue, setAddEditMenuItemValue] =
    useState<AddEditMenuItemValue>(defaultAddEditMenuItemValue);
  const [errorState, setErrorState] = useState<AddEditError>();

  useEffect(() => {
    if (selectedRow) updateAddEditMenuItemValues(selectedRow);

    // eslint-disable-next-line
  }, [
    selectedRow,
    powerBiReportValueOptions,
    parentMenuItemValueOptions,
    helpPageValueOptions,
    unassignedPowerBiReportsData,
  ]);

  useEffect(() => {
    if (powerBiReportsData && selectedRow) {
      setPowerBiReportValueOptions(
        powerBiReportsData?.map(report => {
          const valueOption: ValueOption = {
            label: report.reportName,
            value: report.reportId,
          };
          return valueOption;
        })
      );
    } else {
      setPowerBiReportValueOptions(
        unassignedPowerBiReportsData?.map(report => {
          const valueOption: ValueOption = {
            label: report.reportName,
            value: report.reportId,
          };
          return valueOption;
        })
      );
    }
    if (parentMenuItemsData) {
      setParentMenuItemValueOptions(
        parentMenuItemsData.map(mi => {
          const valueOption: ValueOption = {
            label: mi.text,
            value: mi.id,
          };
          return valueOption;
        })
      );
    }
    if (helpPagesData) {
      setHelpPageValueOptions(
        helpPagesData.map(hp => {
          const valueOption: ValueOption = {
            label: hp.title,
            value: hp.url,
          };
          return valueOption;
        })
      );
    }
    if (rolesData) setRoles(rolesData);
    // eslint-disable-next-line
  }, [
    powerBiReportsData,
    parentMenuItemsData,
    helpPagesData,
    rolesData,
    unassignedPowerBiReportsData,
  ]);

  const updateAddEditMenuItemValues = (selectedRow: GridRowModel) => {
    const selectedMenuItem: MenuItem = selectedRow.row as MenuItem;
    let updatedAddEditMenuItemValue: AddEditMenuItemValue = addEditMenuItemValue
      ? { ...addEditMenuItemValue }
      : defaultAddEditMenuItemValue;

    if (powerBiReportValueOptions && powerBiReportValueOptions.length > 0) {
      const powerBiValue = powerBiReportValueOptions.find(
        x =>
          x.value.toLocaleString().toLowerCase() ===
          selectedMenuItem?.powerBIReportGuid?.toLowerCase()
      );
      if (powerBiValue)
        updatedAddEditMenuItemValue.selectedPowerBiReport = powerBiValue;
    }

    if (parentMenuItemValueOptions && parentMenuItemValueOptions.length > 0) {
      const parentMenuItemValue = parentMenuItemValueOptions.find(
        x => x.value === selectedMenuItem.parentId
      );
      if (parentMenuItemValue)
        updatedAddEditMenuItemValue.selectedParentMenuItem =
          parentMenuItemValue;
    }

    if (helpPageValueOptions && helpPageValueOptions.length > 0) {
      const helpPageValue = helpPageValueOptions.find(
        x => x.value === selectedMenuItem.helpUrl
      );
      if (helpPageValue)
        updatedAddEditMenuItemValue.selectedHelpPage = helpPageValue;
    }

    updatedAddEditMenuItemValue = {
      ...updatedAddEditMenuItemValue,
      menuItemId: selectedMenuItem.id,
      enabled: selectedMenuItem?.active ? selectedMenuItem?.active : false,
      menuItemText: selectedMenuItem?.text ? selectedMenuItem?.text : null,
      parentMenuItemText: selectedMenuItem.parentMenuItemText
        ? selectedMenuItem.parentMenuItemText
        : null,
      menuItemDisplayOrder: selectedMenuItem?.displayOrder
        ? selectedMenuItem?.displayOrder
        : null,
      selectedRoles: selectedMenuItem.roles,
      menuItemTags: selectedMenuItem.tags,
    };

    setAddEditMenuItemValue({
      ...addEditMenuItemValue,
      ...updatedAddEditMenuItemValue,
    });
  };

  const handleSave = async () => {
    const errors = validateAddEditMenuItem(addEditMenuItemValue);
    if (errors?.hasErrors) {
      setErrorState(errors);
      dispatch(
        setAlert({
          message: 'Something went wrong.',
          severity: AlertSeverity.Error,
        })
      );
      return;
    }

    const addEditMenuItem = convertFormDataToMenuItem(addEditMenuItemValue);
    await upsertAction({
      menuItem: {
        ...addEditMenuItem,
        powerBiReportGuid: addEditMenuItemValue.selectedPowerBiReport?.value,
      } as MenuItem,
      roles: addEditMenuItemValue.selectedRoles
        ? [...addEditMenuItemValue.selectedRoles]
        : null,
    } as MenuItemRequest)
      .unwrap()
      .then(resp => {
        const existingRow = rows.find((row: MenuItem) => row.id === resp.id);

        let updatedRows = rows.map(x => x);
        if (existingRow) {
          updatedRows = rows.map((row: MenuItem) =>
            row.id === resp.id ? resp : row
          );
        } else {
          updatedRows.push(resp);
        }
        dispatch(updateTableRows(updatedRows));
        dispatch(setOpenTableModal(false));
        dispatch(
          setAlert({
            message: 'New menu item added.',
            severity: AlertSeverity.Success,
          })
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            message: 'Something went wrong.',
            severity: AlertSeverity.Error,
          })
        );
      });
  };

  return (
    <>
      <ModalHeaderStyled>Add/Edit Report Menu Item</ModalHeaderStyled>
      <Box sx={{ overflow: 'auto', height: '500px' }}>
        <FormWithLabel
          control={
            <TextField
              error={errorState?.textError.isError}
              variant="standard"
              value={addEditMenuItemValue.menuItemText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const updatedNewValue: AddEditMenuItemValue = {
                  ...addEditMenuItemValue,
                  menuItemText: event.target.value,
                };
                setAddEditMenuItemValue(updatedNewValue);
              }}
              sx={{ width: 300 }}
              helperText={errorState?.textError.message}
            />
          }
          label="Menu Item Text"
          labelPlacement="start"
        />

        <FormWithLabel
          control={
            <Autocomplete
              disablePortal
              value={addEditMenuItemValue.selectedParentMenuItem}
              onChange={(event: any, newValue: ValueOption | null) => {
                const updatedNewValue: AddEditMenuItemValue = {
                  ...addEditMenuItemValue,
                  selectedParentMenuItem: newValue,
                };
                setAddEditMenuItemValue(updatedNewValue);
              }}
              options={
                parentMenuItemValueOptions ? parentMenuItemValueOptions : []
              }
              renderInput={params => (
                <TextField {...params} label="Parent Menu Item" />
              )}
              sx={{ width: 300 }}
            />
          }
          label="Parent Menu Item"
          labelPlacement="start"
        />

        <FormWithLabel
          control={
            <Autocomplete
              disablePortal
              value={addEditMenuItemValue.selectedPowerBiReport}
              onChange={(event: any, newValue: ValueOption | null) => {
                const updatedNewValue: AddEditMenuItemValue = {
                  ...addEditMenuItemValue,
                  selectedPowerBiReport: newValue,
                };
                setAddEditMenuItemValue(updatedNewValue);
              }}
              options={
                powerBiReportValueOptions ? powerBiReportValueOptions : []
              }
              renderInput={params => (
                <TextField {...params} label="PowerBI Reports" />
              )}
              sx={{ width: 300 }}
            />
          }
          label="Report"
          labelPlacement="start"
        />

        <FormWithLabel
          control={
            <Autocomplete
              disablePortal
              value={addEditMenuItemValue.selectedHelpPage}
              onChange={(event: any, newValue: ValueOption | null) => {
                const updatedNewValue: AddEditMenuItemValue = {
                  ...addEditMenuItemValue,
                  selectedHelpPage: newValue,
                };
                setAddEditMenuItemValue(updatedNewValue);
              }}
              options={helpPageValueOptions ? helpPageValueOptions : []}
              renderInput={params => (
                <TextField {...params} label="Help Pages" />
              )}
              sx={{ width: 300 }}
            />
          }
          label="Help Documentation"
          labelPlacement="start"
        />

        <FormWithLabel
          control={
            <TextField
              type="number"
              variant="standard"
              value={addEditMenuItemValue.menuItemDisplayOrder}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const updatedNewValue: AddEditMenuItemValue = {
                  ...addEditMenuItemValue,
                  menuItemDisplayOrder: parseInt(event.target.value),
                };
                setAddEditMenuItemValue(updatedNewValue);
              }}
              sx={{ width: 300 }}
            />
          }
          label="Display Order"
          labelPlacement="start"
        />

        <Box
          display="flex"
          p="20px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1">Enabled</Typography>
          <Box display="flex" width={300}>
            <Checkbox
              checked={addEditMenuItemValue.enabled}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const updatedNewValue: AddEditMenuItemValue = {
                  ...addEditMenuItemValue,
                  enabled: event.target.checked,
                };
                setAddEditMenuItemValue(updatedNewValue);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>

        <FormWithLabel
          control={
            <Autocomplete
              multiple
              limitTags={2}
              value={addEditMenuItemValue.selectedRoles}
              onChange={(event: any, newRoles: Role[]) => {
                const updatedNewValue: AddEditMenuItemValue = {
                  ...addEditMenuItemValue,
                  selectedRoles: newRoles,
                };
                setAddEditMenuItemValue(updatedNewValue);
              }}
              options={roles}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={addEditMenuItemValue.selectedRoles.some(
                        x => x.id === option.id
                      )}
                    />
                    {option.name}
                  </li>
                );
              }}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField {...params} label="Roles" placeholder="Roles" />
              )}
            />
          }
          label="Permissions"
          labelPlacement="start"
        />

        <FormWithLabel
          control={
            <Autocomplete
              multiple
              limitTags={2}
              value={
                addEditMenuItemValue.menuItemTags
                  ? addEditMenuItemValue.menuItemTags
                  : []
              }
              onChange={(event: any, newTags: string[]) => {
                const updatedNewValue: AddEditMenuItemValue = {
                  ...addEditMenuItemValue,
                  menuItemTags: newTags,
                };
                setAddEditMenuItemValue(updatedNewValue);
              }}
              options={[]}
              disableCloseOnSelect
              freeSolo
              renderInput={params => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Tags"
                  placeholder="Add tag"
                />
              )}
              style={{ width: 300 }}
            />
          }
          label="Tags"
          labelPlacement="start"
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        padding="20px"
        gap="20px"
        sx={{ borderTop: '1px solid lightgray' }}
      >
        <Button
          variant="text"
          onClick={() => dispatch(setOpenTableModal(false))}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={isAddEditMenuItemNull(addEditMenuItemValue)}
        >
          Save
        </Button>
      </Box>
    </>
  );
};
